import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import YouTube from '../components/YouTube';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "Modern Web Podcast",
  "date": "2020-04-03",
  "promo": "grids",
  "pic": "/img/podcast.jpg",
  "cover": "/img/cover/podcast.jpg",
  "description": "(podcast) Mastering Web Dev through Games",
  "color": "#ff4783"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I was recently invited as a guest on the Modern Web podcast. I had an absolute blast chatting with `}<a parentName="p" {...{
        "href": "https://twitter.com/@JakeDohm"
      }}>{`Jake Dohm`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/@robocell"
      }}>{`Rob Ocel`}</a>{` about Mastery Games.`}</p>
    <p>{`They asked how I got into all this, how Google ended up sponsoring `}<a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{`, what I'm excited about these days, and more. `}</p>
    <p>{`Here's the episode. Grab a cold diet coke and have a listen:`}</p>
    <Action link="https://modernweb.podbean.com/e/s06e18-modern-web-podcast-mastering-web-development-through-games-with-dave-geddes/" mdxType="Action">Listen to the podcast</Action>
    <p>{`Or if you'd like to see my beautiful face, here you go:`}</p>
    <YouTube id="VJNvgpPrjjM" mdxType="YouTube" />
    <p>{`Enjoy!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      